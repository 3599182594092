<template>
    <div>
        <div class="row">
            <div class="text-center col-auto">
                <font-awesome-icon @click="goBack()" icon="fa-solid fa-arrow-left" />
            </div>
            <div class="text-center col">
                Informasi Donasi
            </div>
            <div class="text-center col-auto">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="text-center mt-4">
                    <img :src="assetSuccess" alt="" v-if="donation.status == 'PAID'">
                    <img :src="assetWaiting" alt="" v-else-if="donation.status == 'PENDING'">
                    <img :src="assetWrong" alt="" v-else-if="donation.status == 'CANCELED'">
                </div>
                
                <div class="text-center mt-4 font-weight-bold">
                    Berhasil Melakukan Donasi
                </div>

                <div class="mt-5 text-center">
                    <h4>Rp. {{ donation.amount.toLocaleString() }}</h4>
                </div>

                <div class="mt-2 text-center font-donation-small">
                    Terkonfirmasi {{ donation.confirmed_donation_at }}
                </div>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col font-weight-bold">
                Informasi Donasi
            </div>
        </div>

        <div class="row mt-2 text-muted">
            <div class="col-6">Campaign</div>
            <div class="col-6 text-truncate text-right"> {{ donation.campaign_title }} </div>
        </div>

        <div class="row mt-5">
            <div class="col font-weight-bold">
                Informasi Transaksi
            </div>
        </div>

        <div class="row mt-2 text-muted">
            <div class="col-6">No Transaksi</div>
            <div class="col-6 text-truncate text-right">{{ donation.code }}</div>
        </div>

        <div class="row mt-2 text-muted">
            <div class="col-6">Tanggal</div>
            <div class="col-6 text-truncate text-right">{{ donation.created_at }}</div>
        </div>

        <div class="row mt-2 text-muted" v-if="donation.status == 'PENDING'">
            <div class="col-6">Link Pembayaran</div>
            <div class="col-6 text-truncate text-right">
                <a :href="donation.payment_url" class="badge badge-primary">Bayar Sekarang</a>
            </div>
        </div>
    </div>
</template>

<script>
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faArrowLeft)
import assetSuccess from '../../assets/checklist.png';
import assetWaiting from '../../assets/waiting.png';
import assetWrong from '../../assets/wrong.png';
import axios from 'axios';

export default {
    name: 'HistoryDonationDetail',
    data() {
        return {
            assetSuccess,
            assetWaiting,
            assetWrong,

            id: this.$route.params.id,

            donation: {
                id: '',
                campaign_title: '',
                code: '',
                status: '',
                amount: 0,
                confirmed_donation_at: '',
                created_at: '',
                payment_url: null,
            }
        }
    },

    beforeMount() {
        this.getDetailHistoryDonation();
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },

        getDetailHistoryDonation() {
            axios.get(process.env.VUE_APP_API + 'donation/' + this.id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    console.info(data);

                    this.donation.id = data.id;
                    this.donation.campaign_title = data.campaign_title;
                    this.donation.code = data.code;
                    this.donation.status = data.status;
                    this.donation.amount = data.amount;
                    this.donation.confirmed_donation_at = data.confirmed_donation_at;
                    this.donation.created_at = data.created_at;
                    this.donation.payment_url = data.payment_link;
                }
            });
        }
    }
}

</script>